import Constants from 'expo-constants';

export function intlPolyfill(): void {
  // Polyfills required to use Intl with Hermes engine
  // have to be loaded in a specific order: https://formatjs.io/docs/polyfills/intl-datetimeformat
  // really only android needs this- but Lucca was getting the same error on her iphone so I'm adding it for ios too
  if (Constants.appOwnership === 'expo' || Constants.executionEnvironment === 'storeClient') {
    return;
  }
  import('@formatjs/intl-getcanonicallocales/polyfill');
  import('@formatjs/intl-locale/polyfill');
  import('@formatjs/intl-pluralrules/polyfill');
  import('@formatjs/intl-pluralrules/locale-data/en');
  import('@formatjs/intl-numberformat/polyfill');
  import('@formatjs/intl-numberformat/locale-data/en');
  import('@formatjs/intl-datetimeformat/polyfill');
  import('@formatjs/intl-datetimeformat/locale-data/en');
  import('@formatjs/intl-datetimeformat/add-golden-tz');
}
