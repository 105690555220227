import { Platform } from 'react-native';
import { polyfillGlobal } from 'react-native/Libraries/Utilities/PolyfillFunctions';
import { URL } from 'react-native-url-polyfill/js/URL';
import { URLSearchParams } from 'react-native-url-polyfill/js/URLSearchParams';
import { intlPolyfill } from './polyfills/intl';

export function setupPolyfill(): void {
  if (Platform.OS === 'android' || Platform.OS === 'ios') {
    setupURLPolyfill();
    intlPolyfill();
  }
}

const setupURLPolyfill = (): void => {
  polyfillGlobal('URL', () => URL);
  polyfillGlobal('URLSearchParams', () => URLSearchParams);
};
